import React, { useState } from 'react';
import * as DxChart from 'devextreme-react/chart';
import DataGrid from 'devextreme-react/data-grid';
import {
    IChartTooltip,
    IChartTitle,
    IChartLoadingIndicator,
    getLiasPalette,
    ChartWrapper,
} from '../shared';

import { IMargin } from '../shared';

import { LsdxBarChart, LsdxElement } from '../../..';
import { CreateCustomDataSource } from '../../helper/datasource';
import { defaultChartSettings } from './defaults';
import { ChartPanel, Views } from '../shared/chartPanel';

/**
 * Defines the props of the Chart component.
 */
export interface ILsChartProps {
    readonly adaptiveLayout?: {
        height?: number,
        keepLabels?: boolean,
        width?: number,
    },
    readonly size?: {
        width?: number;
        height?: number;
    };
    readonly palette?: string | string[];

    readonly redrawOnResize?: boolean;
    readonly chartTitle?: string;
    readonly chartData: DxChart.ISeriesProps;
    readonly animationData?: DxChart.IAnimationProps;
    readonly legendData?: DxChart.ILegendProps;

    readonly tooltipData?: IChartTooltip;
    readonly titleData?: IChartTitle;
    readonly loadingIndicator?: IChartLoadingIndicator;
    readonly margin?: IMargin,

    readonly minDiameter?: number,

    readonly type: 'bar' | 'line',

    readonly data: [];

    readonly filterValue?: string | Array<any> | Function;

    readonly centerText?: string;

    readonly onInitialized?: (e: { component?: LsdxBarChart | undefined; element?: LsdxElement | undefined; }) => any,

    readonly textColor?: string;

    readonly textFontSize?: string;

    readonly orientation?: boolean;

    readonly primaryColor?: string;

    readonly primaryTextColor?: string;

}

export const LsChart = (props: ILsChartProps) => {
    const [view, setView] = useState<Views>(Views.Chart);
    const chartProps = { ...defaultChartSettings, ...props };

    const usedPalette = (props.palette && Array.isArray(props.palette))
        ? props.palette
        : getLiasPalette(chartProps.palette as string, chartProps.primaryColor).simpleSet;

    const dataSource = CreateCustomDataSource(props.data, undefined, undefined, props.filterValue, undefined, true);
    const columns = [{
        dataField: chartProps.chartData.argumentField,
        key: chartProps.chartData.argumentField,
        minWidth: 32,
        encodeHtml: false,
    },
    {
        dataField: chartProps.chartData.valueField,
        key: chartProps.chartData.valueField,
        minWidth: 32,
        encodeHtml: false,
    }];

    return (<ChartWrapper $primaryColor={chartProps.primaryColor} $primaryTextColor={chartProps.primaryTextColor}>
        <ChartPanel view={view} onSwitch={setView} />
        {view === Views.Chart &&
            <DxChart.Chart
                adaptiveLayout={chartProps.adaptiveLayout}
                rotated={!!chartProps.orientation}
                title={chartProps.titleData}
                palette={usedPalette}
                dataSource={dataSource}
                redrawOnResize={chartProps.redrawOnResize}
                onInitialized={chartProps.onInitialized}
            >
                <DxChart.Legend {...chartProps.legendData} />
                <DxChart.Animation {...chartProps.animationData} />
                <DxChart.Series {...chartProps.chartData} type={chartProps.type} />
                <DxChart.CommonAxisSettings>
                    <DxChart.Label
                        wordWrap="none"
                        overlappingBehavior="stagger"
                    />
                </DxChart.CommonAxisSettings>
                <DxChart.Tooltip {...chartProps.tooltipData} />
                <DxChart.Size {...chartProps.size} />
            </DxChart.Chart>
        }
        {view === Views.Table &&
            <DataGrid
                dataSource={dataSource}
                noDataText='Geen gegevens beschikbaar'
                columnAutoWidth={true}
                showBorders={true}
                wordWrapEnabled={true}
                repaintChangesOnly={true}
                allowColumnReordering={false}
                columns={columns}
                paging={{ enabled: false }}
            />
        }
    </ChartWrapper>);
};
