import React from 'react';
import Select from 'react-select';
import { IDataItemProps, IInputSelectProps } from './Props';
// tslint:disable:no-submodule-imports
import { palette } from '../../styleguide';
// tslint:enable:no-submodule-imports

/* tslint:disable:no-magic-numbers */

export const InputSelect = <TValue extends string | number | boolean>({
  id,
  name,
  options,
  selectedOption,
  placeholder,
  clearable,
  searchable,
  onChange,
  isMultiSelector,
  maxTagsCount,
  disabled
}: IInputSelectProps<TValue>) => {

  return (
    <Select
      name={name}
      options={options}
      closeMenuOnSelect={!isMultiSelector}
      value={selectedOption}
      placeholder={placeholder}
      isClearable={clearable}
      isSearchable={searchable}
      onChange={onChange}
      isDisabled={disabled}
      inputId={id}
      isMulti={isMultiSelector}
      isOptionDisabled={() => isMultiSelector && (selectedOption as IDataItemProps<TValue>[]).length >= maxTagsCount}
      noOptionsMessage={() => 'Niets gevonden'}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={true}
      styles={{
        // TODO: change to devextreme multiselect element
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? `${palette.primary2}` : `${palette.primary3}`,
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: palette.primary3,
        }),
        multiValueRemove: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused ? `${palette.primary2}` : `${palette.primary3}`,
          ':hover': {
            backgroundColor: palette.grey3,
            color: palette.white,
          },
        }),
      }}
    />
  );
};
