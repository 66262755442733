import { throttle } from "lodash";
import { useEffect, useRef, MutableRefObject, useState } from "react";
import { differenceInMilliseconds } from 'date-fns';
import { DateUtils } from "@liasincontrol/core-service";

const DEFAULT_ACTIVITY_EVENTS = [
    'click',
    'mousemove',
    'keydown',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'focus',
    'scroll'
];

//TODO => move in basics/hooks if it will be used in more modules
export class IdleTimer {
    timeout = 0; //duration

    //points in time
    startTime = 0;
    halfTime = 0;
    warnTime = 0;
    expiredTime = 0;

    intervalId: any;

    onActive: MutableRefObject<(timer: IdleTimer) => void>;
    onWarn: MutableRefObject<(timer: IdleTimer) => void>;
    onIdle: MutableRefObject<(timer: IdleTimer) => void>;
    onTick?: MutableRefObject<(timer: IdleTimer) => void>;

    isAfterWarn = false;
    isAfterHalf = false;
    isActive = false;

    constructor(
        onActive: MutableRefObject<(timer: IdleTimer) => void>,
        onWarn: MutableRefObject<(timer: IdleTimer) => void>,
        onIdle: MutableRefObject<(timer: IdleTimer) => void>,
        onTick?: MutableRefObject<(timer: IdleTimer) => void>
    ) {
        this.onActive = onActive;
        this.onWarn = onWarn;
        this.onIdle = onIdle;
        this.onTick = onTick;
    }

    /**
     * start the timer
     * t-->........................................................
     * start.................half........warn.....idle(expires)....
     * reset goes back to start from anywhere
     */
    start = (expires: string) => {
        this.timeout = this.parseTimeStamp(expires);
        this.resetRemainingTimes();
        this.startInterval();
        this.isActive = true;
    };

    stop = () => {
        this.removeEvents();
        clearInterval(this.intervalId);
        this.isActive = false;
    };

    reset = (expires: string) => {
        this.stop();
        this.start(expires);
    };

    getElapsedTime = () => {
        //start==========now............
        const now = Date.now();
        return now - this.startTime;
    };

    getRemainingTime = () => {
        //start......now========exp......
        const now = Date.now();
        return this.expiredTime - now;
    };

    private startInterval = () => {
        this.intervalId = setInterval(() => {
            const now = Date.now();
            //start.....0.....half...1....warn....2....idle(expires)...3.
            if (now > this.expiredTime) {
                //3
                this.removeEvents();
                this.stop();
                this.onIdle?.current(this);
            } else if (now > this.warnTime && !this.isAfterWarn) {
                //2
                this.isAfterWarn = true;
                this.removeEvents();
                this.onWarn?.current(this);
            } else if (now > this.halfTime && !this.isAfterHalf) {
                //1
                this.isAfterHalf = true;
                this.addEvents();
            }
            this.onTick?.current?.(this);
        }, 1000);
    };

    private parseTimeStamp(timeStamp: string): number {
        return differenceInMilliseconds(DateUtils.fromInvariantString(timeStamp), Date.now());
    }

    private updateExpiredTime = throttle(() => {
        if (this.isAfterHalf && !this.isAfterWarn) {
            this.resetRemainingTimes();
            this.onActive?.current(this);
        }
    }, 250);

    private resetRemainingTimes = () => {
        const now = Date.now();
        this.startTime = now;
        this.halfTime = now + Math.ceil(this.timeout * 0.5);
        this.warnTime = now + Math.ceil(this.timeout * 0.8);
        this.expiredTime = now + this.timeout;
        this.isAfterWarn = false;
        this.isAfterHalf = false;
    };

    private addEvents = () => {
        DEFAULT_ACTIVITY_EVENTS.forEach(eventName =>
            window.addEventListener(eventName, this.updateExpiredTime));
    };

    private removeEvents = () => {
        DEFAULT_ACTIVITY_EVENTS.forEach(eventName =>
            window.removeEventListener(eventName, this.updateExpiredTime));

    };
}

export const useIdleTimer = (
    onActive: (timer: IdleTimer) => void,
    onWarn: (timer: IdleTimer) => void,
    onIdle: (timer: IdleTimer) => void,
    onTick: (timer: IdleTimer) => void
) => {
    const [idleTimer, setIdleTimer] = useState<IdleTimer>();
    // TODO: Do I need this?
    // https://usehooks-ts.com/react-hook/use-interval
    const onActiveRef = useRef(onActive);
    const onWarnRef = useRef(onWarn);
    const onIdleRef = useRef(onIdle);
    const onTickRef = useRef(onTick);

    useEffect(() => {
        onActiveRef.current = onActive;
    }, [onActive]);
    useEffect(() => {
        onWarnRef.current = onWarn;
    }, [onWarn]);
    useEffect(() => {
        onIdleRef.current = onIdle;
    }, [onIdle]);
    useEffect(() => {
        onTickRef.current = onTick;
    }, [onTick]);


    useEffect(() => {
        //Need only one instance of IdleTimer to not lose times
        const timer = new IdleTimer(onActiveRef, onWarnRef, onIdleRef, onTickRef);
        setIdleTimer(timer);

        return () => {
            timer.stop();
            setIdleTimer(undefined);
        };
    }, []);

    return idleTimer;
};

/*
idletimer
- (expiredOn UTC) 
methods
start / stop / reset event (bindable)
//run once each half time after reset
onActive => () => { // here the getlease method // } 
onWarn => () => { show warn modal }
onIdle => () => { show gone modal } 
3 moments start ------ halftime (eventlistener) -> keydown
if im still here/onActive =>  getNewLease().then(reset(newExpiredOn))


expiresOn: "2023-02-14T11:18:15.0999087+00:00"

const duration = moment(leaseInfo.expiresOn).subtract(1, 'minute').diff(moment().utc(), "milliseconds");

*/
