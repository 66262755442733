import React, { JSX, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Feedback, Label, MandatoryIcon } from '@liasincontrol/ui-basics';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Styled from './index.styled';
import { HelpText } from '../helptext';
import { getMuiIconAsSvgString } from '../modal';


type FullHeightWrapperProps = {
    fullHeight?: boolean,
    children?: React.ReactNode
};

const FullHeightWrapper: React.FC<FullHeightWrapperProps> = (props) => (
    <Styled.HoverContainer $fullHeight={props.fullHeight}>
        {props.children}
    </Styled.HoverContainer>
);

type LabelProps = {
    id: string,
    label?: string | JSX.Element,
}

const LabelElement: React.FC<LabelProps> = (props) => props.label ? (
    <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />
) : <span />

type HelpTextProps = {
    id: string,
    helpText?: { title?: string, text?: string } | null
}

export const HelpTextElement: React.FC<HelpTextProps> = (props) => props.helpText && props.helpText.text
    ? (<HelpText id={props.id} title={props.helpText.title}>{props.helpText.text}</HelpText>)
    : null;

type MaximizeProps = {
    id: string,
    canMaximize?: boolean,
    maximized?: boolean,
    onMaximize?: () => void
}

const MaximizeElement: React.FC<MaximizeProps> = (props) => {
    if (!props.canMaximize) {
        return null;
    }

    return (<Button
        hint={props.maximized ? 'Minimaliseren' : 'Maximaliseren'}
        className='maximize-button ml-025'
        key={`btn-maximize-${props.id}`}
        type="default"
        stylingMode="text"
        icon={getMuiIconAsSvgString(props.maximized ? CloseFullscreenIcon : OpenInFullIcon)}
        onClick={props.onMaximize} />
    );
};

type FeedbackProps = {
    error?: string | React.ReactNode,
    withCounter?: boolean,
    feedback?: React.ReactNode;
    children?: React.ReactNode;
    counter?: React.ReactNode;
    id?: string;
}

export type WithFieldBaseType<V> = FullHeightWrapperProps & LabelProps & HelpTextProps & FeedbackProps & MaximizeProps & {
    as?: React.ElementType;
    value: V,
    disabled?: boolean,
    mandatory?: boolean,
    success?: boolean;
    onFocus?: (e: any) => void,
    onBlur?: (e: any) => void,
    onInput?: (e: any) => void,
    onChange?: (value: V, previousValue?: V) => void;
    className?: string;
    withoutFeedback?: boolean;
    readOnly?: boolean;
}

export const withField = <V, U extends WithFieldBaseType<V>>(Component: React.ComponentType<Omit<U, "as">>) => (props: U) => {
    const { as: Container = React.Fragment } = props;
    const onFocus = (e) => {
        setFocused(true);
        if (props.onFocus) {
            props.onFocus(e);
        }
    };
    const onBlur = (e) => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur(e);
        }
    };
    const onChange = (value: V, previousValue?: V) => {
        if (props.onChange) {
            props.onChange(value, previousValue);
        }
    };
    const [focused, setFocused] = useState(false);

    const childProps = {
        onBlur,
        onChange,
        onFocus,
        onFocusIn: onFocus,
        onFocusOut: onBlur,
        onInput: (e) => props.onChange?.(e.event.currentTarget.value),
        field: { ...props, as: Container }, //TODO is this used anywhere???? 
        value: props.value,
        error: !!props.error,
        success: !!props.success,
        focused,
        fullHeight: props.fullHeight,
    };

    return (
        <FullHeightWrapper fullHeight={props.fullHeight}>
            <Styled.LabelContainer>
                <span>
                    <LabelElement id={props.id} label={props.label} />
                    {props.label && <HelpTextElement id={`${props.id}-hint`} helpText={props.helpText} />}
                </span>
                <span>
                    {!props.disabled && props.mandatory && <MandatoryIcon />}
                    <MaximizeElement id={props.id} canMaximize={props.canMaximize} maximized={props.maximized} onMaximize={props.onMaximize} />
                </span>
            </Styled.LabelContainer>
            <Component {...{ ...props, ...childProps }} />
            {!props.withoutFeedback && <Feedback error={!!props.error} children={props.error} counter={focused ? props.feedback : ''} id={`${props.id}-feedback`} withCounter={isCounterFeedback(props.feedback)} />}
        </FullHeightWrapper>
    );
};

/**
 * Matches number/number eg `"10/100"`
 */
const isCounterFeedback = (input: any) =>
    typeof input === 'string' && /^\d+\/\d+$/.test(input);
