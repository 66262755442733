import React, { JSX, useEffect, useRef } from 'react';
import TabPanel, { ITabPanelOptions } from 'devextreme-react/tab-panel';
import { DEFAULT_DATE_FORMAT, StringUtils } from '@liasincontrol/core-service';
import { IconSize } from '@liasincontrol/ui-basics';
import { groupCellRender } from '../../helpers/renderModes';
import { useTabPanelNavButtonsStopPropagation } from '../../helpers/hooks';
import * as TypeFormatter from '../helper/formatters';
import { DxTabsIconPosition, ILsColumnProps, TabPositions } from '../..';
import Styled from './index.styled';
import { MaxWidthContainer } from '../../helpers/renderModes';

type Props<T> = ITabPanelOptions & {
    readonly selectedTab?: number;
    readonly icons: Record<string, any>;
    readonly iconSize?: IconSize;
    readonly renderTitle?: (data: T, index: number) => React.ReactNode;

    readonly getContainerItemContent?: () => JSX.Element;

    readonly tabsPosition?: TabPositions;

    readonly tabIconsPosition?: DxTabsIconPosition;
    readonly columns?: ILsColumnProps[],
    readonly headerField?: string,
    readonly onContentReady?: (e: any) => void;

}

export const LsTabDs = <T extends { title: string, text?: string, icon?: string }>(props: Props<T>) => {
    const { columns, headerField } = props;
    const headerColumn = columns?.find(c => c.dataField === headerField);
    const detailColumns = columns?.filter(column => column.visible);
    const tabRef = useRef(null);

    useEffect(() => {
        tabRef.current?.instance()?.option('selectedItems', []);
        tabRef.current?.instance()?.option('selectedIndex', 0);
    }, [props.dataSource]);

    useTabPanelNavButtonsStopPropagation(tabRef);

    return (
        <TabPanel
            id={`tabpanelDs-${props.id}`}
            ref={tabRef}
            dataSource={props.dataSource}
            selectedIndex={props.selectedTab}
            repaintChangesOnly={false}
            animationEnabled={false}
            swipeEnabled={false}
            showNavButtons={true}
            tabsPosition={props.tabsPosition}
            iconPosition={props.tabIconsPosition}
            itemTitleRender={(data) => itemTitleRender(data, headerColumn, props.icons, props.tabIconsPosition, props.iconSize, 160)}
            itemRender={(data) => itemRender(data, detailColumns, props.icons, props.iconSize)}
        />
    );
};

const itemTitleRender = (data, headerColumn, icons, tabIconPosition, iconSize, maxWidth?) => {
    if (!data || (isCombinedField(headerColumn) ? !data?.[`${headerColumn?.dataField}Name`] : !data?.[headerColumn?.dataField])) {
        return (
            <Styled.TabHeader>
                <MaxWidthContainer $maxWidth={maxWidth}>Titel veld</MaxWidthContainer>
            </Styled.TabHeader>
        );
    }

    return (
        <Styled.TabHeader>
            {headerColumn?.groupRenderMode
                ? groupCellRender(data, headerColumn, icons, iconSize, tabIconPosition, maxWidth)
                : cellRender(data, headerColumn, maxWidth)}
        </Styled.TabHeader>
    );
};

const itemRender = (data, columns, icons, iconSize) => {
    if (!columns || columns.length === 0 || !data) return <></>;
    const panelNodes = columns?.reduce((acc, column) => {
        if (!column) {
            return acc;
        }

        const node = !!data?.[column.dataField] || (isCombinedField(column) && !!data?.[`${column.dataField}Name`])
            ? <Styled.TabField>
                {!!column.showCaption && <Styled.TabFieldLabel withMargin >{column.caption}</Styled.TabFieldLabel>}
                {column?.groupRenderMode
                    ? groupCellRender(data, column, icons, iconSize)
                    : cellRender(data, column)}
            </Styled.TabField>
            : null;

        return [...acc, node];
    }, []);

    return (
        <>
            {panelNodes}
        </>
    );
};

const cellRender = (data, column, maxWidth?) => {
    const text = data?.[column?.dataField];
    if (!text) return;
    switch (column.dataType) {
        case "boolean":
            return <>{text ? 'Ja' : 'Nee'}</>;
        case "number":
            const computedNumber = column.divide && column.divide > 1 ? Math.round(text / column.divide) : text;
            return <>{TypeFormatter.decimalFormatter(computedNumber, column.format)}</>;
        case "datetime":
            return <>{TypeFormatter.dateTimeFormatter(text, DEFAULT_DATE_FORMAT)}</>;
        case "html":
        case "string":
            if (column.format === "hyperlink") {
                const modifiedHtml = text.replace(/<a([^>]+)>/, '<a$1 target="_blank">');
                return (<MaxWidthContainer $maxWidth={maxWidth} dangerouslySetInnerHTML={StringUtils.toRawMarkup(modifiedHtml)}></MaxWidthContainer>);
            }
            return (<MaxWidthContainer $maxWidth={maxWidth} dangerouslySetInnerHTML={StringUtils.toRawMarkup(text)}></MaxWidthContainer>);
        default:
            return <>{text}</>;
    }
};

const isCombinedField = (column) => {
    return (column?.propertyGroupKind === 'OptionField' || column?.propertyGroupKind === 'ElementDefinition');
};
