import React from 'react';
import { connect } from 'react-redux';
import { Heading2, PageTitle, PanelGrid, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { Section, TextImageWidget } from '@liasincontrol/ui-elements';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, MeasureMomentsActionCreator, ModulesActionCreator, State, SvgIconActionCreator, WorkflowTemplateActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { PerformanceIconTiles } from './PerformanceIconTiles';
import { PerformanceTaskList } from './PerformanceTaskList';
import { UserIdentity } from '@liasincontrol/auth-service';

/**
 * Defines the props of the Cockpit view component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the Performance cockpit view.
 */
const Cockpit: React.FC<Props> = (props) => {

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance]);
        return null;
    }

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Cockpit Performance</Heading2>
                </PageTitle>
                <PanelGrid>
                    <TextImageWidget
                        title='LIAS Performance'
                        text='Voer beleid eenvoudig op en vertaal deze naar onderliggende doelstellingen, prestaties en activiteiten.
                            Monitor de voortgang en presenteer deze voortgang gemakkelijk in Publisher.'
                    />
                    <PerformanceIconTiles elementDefinitions={props.elementDefinitions.items} measureMoments={props.measureMoments.items} />
                    <Section look='white' grid={true} colSpan={3} rowSpan={2}>
                        <PerformanceTaskList
                            workflowTemplates={props.workflowTemplates.items}
                            elementDefinitions={props.elementDefinitions.items}
                            measureMoments={props.measureMoments.items}
                            icons={props.icons.items}
                        />
                    </Section>
                </PanelGrid>
            </WrapperContent>
        </Wrapper>
    );
};

const mapStateToProps = (state: State) => {
    return {
        elementDefinitions: state.elementdefinitions[ActionSource.Performance],
        modules: state.modules[ActionSource.Performance],
        workflowTemplates: {
            items: state.workflowtemplates.items,
            status: state.workflowtemplates.status,
        },
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
        icons: state.icons,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Performance, data: { moduleId: module?.id } }));
        },
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Performance, data: {} }));
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Cockpit);
export { Component as index };
