import React, { useCallback } from 'react';
import { withField, WithFieldBaseType } from "../field";
import { InputToggle } from '@liasincontrol/ui-basics';


export type ToggleFieldProps = WithFieldBaseType<boolean> & {
    textOn: string;
    textOff: string;
    isSmall: boolean
}

export const ToggleField = withField<boolean, ToggleFieldProps>(
    (props) => {
        const { id, textOn, textOff, value, isSmall, onChange, ...rest } = props;

        const onValueChanged = useCallback((e) => {
            onChange?.(e);
        }, [onChange]);

        return <InputToggle
            id={props.id}
            key={props.id}
            selected={value}
            textOff={textOff}
            textOn={textOn}
            isSmall={isSmall}
            onChange={onValueChanged}
            {...rest} />;
    }
);
