import React, { useState } from 'react';
import { Overlay, Tooltip } from '@liasincontrol/ui-basics';
import { Button } from '@liasincontrol/ui-devextreme';
import Styled from './index.styled';

type Props = {
    readonly look?: 'inverted' | 'default',
    readonly menuAction: MenuAction,
    readonly contextItem: string,
    readonly children?: React.ReactNode,
    readonly icon?: string,
    readonly highlightPopup?: boolean,
    readonly position: 'bottom' | 'left' | 'default' | 'dynamic',
    readonly overlayOnclick?: () => void,
}

export enum MenuAction {
    Context = "context",
    Main = "hoofd"
}

//TODIO=> need rewrite
/**
 * Represents a UI component that renders a context menu wrapper.
 */
export const ContextMenu: React.FC<Props> = (props) => {
    const [popOver, setPopOver] = useState<boolean>(false);

    const Popover =
        props.position === 'bottom' ? Styled.BottomPopover :
            (props.position === 'left' ? Styled.LeftPopover : Styled.SidePopover);

    const onPopOver = (event, showPopOver: boolean) => {
        setPopOver(showPopOver);
        if (props.overlayOnclick && !showPopOver) props.overlayOnclick();
        event.stopPropagation();
    };

    return (<>
        {props.position !== 'dynamic' &&
            <Styled.Wrap $withPadding={props.menuAction === MenuAction.Context}>
                <Button
                    hint={`${props.menuAction === MenuAction.Context ? `Open ${props.menuAction}-menu voor ${props.contextItem}` : `Open ${props.menuAction}-menu`}`}
                    type="normal"
                    stylingMode='text'
                    className={`btn-large ${props.look === 'inverted' ? 'dx-button-normal-inverted' : 'default'}`}
                    icon={props.icon}
                    onClick={(event) => {
                        onPopOver(event?.event, true);
                    }} />
                <Overlay onClick={(event: React.MouseEvent) => onPopOver(event, false)} relative visible={popOver} opacity={props.highlightPopup ? 0 : 0.1}>
                    <Popover onClick={(event: React.MouseEvent) => onPopOver(event, false)} >
                        <Styled.ButtonPlaceholderWithMargin>
                            {props.contextItem === 'menu' &&
                                <Button
                                    hint={`${props.menuAction === MenuAction.Context ? `Open ${props.menuAction}-menu voor ${props.contextItem}` : `Open ${props.menuAction}-menu`}`}
                                    type="normal"
                                    stylingMode='text'
                                    className='btn-large'
                                    icon={props.icon}
                                    onClick={(event) => onPopOver(event.event, false)}
                                />
                            }
                            {props.children}
                        </Styled.ButtonPlaceholderWithMargin>
                    </Popover>
                </Overlay>
            </Styled.Wrap>
        }
        {props.position === 'dynamic' &&
            <Tooltip isTooltipVisible={popOver}
                content={props.children}
                displayOverlay={true}>
                <Button
                    type="default"
                    stylingMode='text'
                    icon={props.icon}
                    hint={`${props.menuAction === MenuAction.Context ? `Open ${props.menuAction}-menu voor ${props.contextItem}` : `Open ${props.menuAction}-menu`}`}
                    onClick={() => { setPopOver(true); }} />
            </Tooltip>
        }</>
    );
}
