
/* localisation: messages for html editor */

export const Dictionary = {
    "nl": {
        "Yes": "Ja",
        "No": "Nee",
        "Cancel": "Annuleer",
        "Clear": "Doorzichtig",
        "Done": "Gedaan",
        "Loading": "Bezig met laden...",
        "Select": "Selecteer...",
        "Search": "Zoeken",
        "Back": "Terug",
        "OK": "OK",

        "dxHtmlEditor-dialogColorCaption": "Lettertype kleur",
        "dxHtmlEditor-dialogBackgroundCaption": "Achtergrond kleur",
        "dxHtmlEditor-dialogLinkCaption": "Link",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Tekst",
        "dxHtmlEditor-dialogLinkTargetField": "Open link in een nieuw venster",
        "dxHtmlEditor-dialogImageCaption": "Afbeelding",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Alternatieve tekst",
        "dxHtmlEditor-dialogImageWidthField": "Breedte (px)",
        "dxHtmlEditor-dialogImageHeightField": "Hoogte (px)",

        "dxHtmlEditor-dialogInsertTableRowsField": "Rijen",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Kolommen",
        "dxHtmlEditor-dialogInsertTableCaption": "Tabel invoegen",

        "dxHtmlEditor-dialogUpdateImageCaption": "Update Image",
        "dxHtmlEditor-dialogImageUpdateButton": "Update",
        "dxHtmlEditor-dialogImageAddButton": "Add",
        "dxHtmlEditor-dialogImageSpecifyUrl": "From the Web",
        "dxHtmlEditor-dialogImageSelectFile": "From This Device",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Keep Aspect Ratio",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Encode to Base64",

        "dxHtmlEditor-heading": "Titel",
        "dxHtmlEditor-normalText": "Normale tekst",
        "dxHtmlEditor-background": "Achtergrondkleur",
        "dxHtmlEditor-bold": "Vetgedrukt",
        "dxHtmlEditor-color": "Lettertype kleur",
        "dxHtmlEditor-font": "Font",
        "dxHtmlEditor-italic": "Cursief",
        "dxHtmlEditor-link": "Voeg een link toe",

        "dxHtmlEditor-image": "Add Image",
        "dxHtmlEditor-size": "Size",
        "dxHtmlEditor-strike": "Strikethrough",
        "dxHtmlEditor-subscript": "Subscript",
        "dxHtmlEditor-superscript": "Superscript",
        "dxHtmlEditor-underline": "Onderstrepen",
        "dxHtmlEditor-blockquote": "Blockquote",
        "dxHtmlEditor-header": "Header",
        "dxHtmlEditor-increaseIndent": "Increase Indent",
        "dxHtmlEditor-decreaseIndent": "Decrease Indent",
        "dxHtmlEditor-orderedList": "Numerieke opsomming",
        "dxHtmlEditor-bulletList": "Lijst met opsommingstekens",
        "dxHtmlEditor-alignLeft": "Links uitlijnen",
        "dxHtmlEditor-alignCenter": "Tekst in het midden uitlijnen",
        "dxHtmlEditor-alignRight": "Rechts uitlijnen",
        "dxHtmlEditor-alignJustify": "Align Justify",
        "dxHtmlEditor-codeBlock": "Code Block",
        "dxHtmlEditor-variable": "Add Variable",
        "dxHtmlEditor-undo": "Undo",
        "dxHtmlEditor-redo": "Redo",
        "dxHtmlEditor-clear": "Verwijder opmaak",
        "dxHtmlEditor-insertTable": "Tabel invoegen",
        "dxHtmlEditor-insertHeaderRow": "Kop toevoegen",
        "dxHtmlEditor-insertRowAbove": "Rij erboven invoegen",
        "dxHtmlEditor-insertRowBelow": "Rij eronder invoegen",
        "dxHtmlEditor-insertColumnLeft": "Kolom links invoegen",
        "dxHtmlEditor-insertColumnRight": "Kolom rechts invoegen",
        "dxHtmlEditor-deleteColumn": "Verwijder kolom",
        "dxHtmlEditor-deleteRow": "Rij verwijderen",
        "dxHtmlEditor-deleteTable": "Tabel verwijderen",
        "dxHtmlEditor-cellProperties": "Cell Properties",
        "dxHtmlEditor-tableProperties": "Table Properties",
        "dxHtmlEditor-insert": "Insert",
        "dxHtmlEditor-delete": "Delete",
        "dxHtmlEditor-border": "Border",
        "dxHtmlEditor-style": "Style",
        "dxHtmlEditor-width": "Width",
        "dxHtmlEditor-height": "Height",
        "dxHtmlEditor-borderColor": "Color",
        "dxHtmlEditor-tableBackground": "Background",
        "dxHtmlEditor-dimensions": "Dimensions",
        "dxHtmlEditor-alignment": "Alignment",
        "dxHtmlEditor-horizontal": "Horizontal",
        "dxHtmlEditor-vertical": "Vertical",
        "dxHtmlEditor-paddingVertical": "Vertical Padding",
        "dxHtmlEditor-paddingHorizontal": "Horizontal Padding",
        "dxHtmlEditor-pixels": "Pixels",
        "dxHtmlEditor-list": "List",
        "dxHtmlEditor-ordered": "Ordered",
        "dxHtmlEditor-bullet": "Bullet",
        "dxHtmlEditor-align": "Align",
        "dxHtmlEditor-center": "Center",
        "dxHtmlEditor-left": "Left",
        "dxHtmlEditor-right": "Right",
        "dxHtmlEditor-indent": "Indent",
        "dxHtmlEditor-justify": "Justify",
    }
};