import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';

const FlexWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrap = styled.div`
    margin-top: ${px(16)};
    margin-right: ${px(16)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h3 {
        margin-bottom:  ${px(16)};
    }

    button {
        margin-bottom:  ${px(16)};
        align-self: flex-start;
    }
`;

const ThumbWrap = styled.div`   
    text-align: center;    
    p {
        text-align: center;
    }

    i {
        width:${px(420)}; 
        max-width: ${px(420)};
    }
`;

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    > :only-child {
        align-self: flex-end;
        margin-left: auto;
    }
`;

const LayoutGrid = styled.div`
    display: grid;
    height: calc(100% - 5rem);
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto min-content min-content;
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(20)};
`;

const ColumnWrapper = styled.div<StyledColumnType>`
    background-color: ${palette.white};
    border-radius: ${px(6)};
    grid-column: ${({ $colStart, $colSpan }) => (`${$colStart} / span ${$colSpan ? $colSpan : $colStart + 1}`)};
    grid-row: ${({ $rowStart, $rowSpan }) => (`${$rowStart} / span ${$rowSpan ? $rowSpan : $rowStart + 1}`)};
`;

const ContentColumn = styled.div<StyledColumnType>`
    grid-column: ${({ $colStart, $colSpan }) => (`${$colStart} / span ${$colSpan ? $colSpan : $colStart + 1}`)};
    grid-row: ${({ $rowStart, $rowSpan }) => (`${$rowStart} / span ${$rowSpan ? $rowSpan : $rowStart + 1}`)};
    display: flex;
    flex-direction: column;
    padding: 0 ${px(24)};
    #input-plain-text, #output-plain-text {
        height: 100%;
    }
`;

const FloatingWrapper = styled.div`
    right: ${px(24)};
    bottom: ${px(-10)};
`;

type StyledColumnType = {
    $colStart: number, 
    $colSpan: number, 
    $rowStart: number, 
    $rowSpan: number
}


const styledComponents = {
    LayoutGrid, ColumnWrapper, ContentColumn, Wrap, FlexWrapper, ThumbWrap, ActionWrapper, FloatingWrapper
};

export default styledComponents;
