import React, { useState } from 'react';
import { convert } from 'html-to-text';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { Shared } from '@liasincontrol/data-service';
import { Heading2, Heading3, IconSize, palette, Text } from '@liasincontrol/ui-basics';
import { getMuiIconAsSvgString, LoadPanel } from '@liasincontrol/ui-devextreme';
import { MultiLineTextElement, SelectElement, TabularNumbers } from '@liasincontrol/ui-elements';
import { LsModal, Button } from '@liasincontrol/ui-devextreme';
import { TextAssistantErrorDialog } from '../TextAssistantErrorDialog';
import Styled from './index.styled';

type Props = {
    textAssistantSkills: Domain.Shared.TextAssistantSkill[],
    textContent?: string,
    standAlone?: boolean,
    onCancel?: () => void,
};

type TextAssistantOptions = {
    skill?: string,
    content: string,
    returned: string[],
    loading: boolean,
    copyDisabled: boolean,
    currentResponse: number,
};

const convertOptions = {
    wordwrap: null,
    selectors: [
        { selector: 'a.button', format: 'skip' },
        { selector: 'table', format: 'dataTable' },
    ]
};

export const textAssistantSvgIconStringWithColor = (color: string) => {
    return `
    <svg version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
            <path style="fill:none;stroke:${color};stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" d="M127.467 432.733H111.4c-35.47 0-64.267-28.8-64.267-64.267 0-26.598 21.598-48.2 48.2-48.2H79.267C43.797 320.267 15 291.467 15 256s28.797-64.267 64.267-64.267h16.067c-26.602 0-48.2-21.602-48.2-48.2 0-35.467 28.797-64.267 64.267-64.267h16.067M384.533 432.733H400.6c35.47 0 64.267-28.8 64.267-64.267 0-26.598-21.598-48.2-48.2-48.2h16.067c35.47 0 64.267-28.8 64.267-64.267s-28.797-64.267-64.267-64.267h-16.067c26.602 0 48.2-21.602 48.2-48.2 0-35.467-28.797-64.267-64.267-64.267h-16.067"/>
            <path style="fill:none;stroke:${color};stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" d="M159.6 111.4c-17.735 0-32.133-14.396-32.133-32.133C127.467 43.8 156.264 15 191.733 15S256 43.8 256 79.267C256 43.8 284.797 15 320.267 15s64.267 28.8 64.267 64.267c0 17.738-14.398 32.133-32.133 32.133M159.6 400.6c-17.735 0-32.133 14.396-32.133 32.133 0 35.467 28.797 64.267 64.267 64.267S256 468.2 256 432.733C256 468.2 284.797 497 320.267 497s64.267-28.8 64.267-64.267c0-17.738-14.398-32.133-32.133-32.133M175.667 304.2v-64.267a32.118 32.118 0 0 1 9.412-22.718A32.106 32.106 0 0 1 207.8 207.8a32.103 32.103 0 0 1 22.721 9.415 32.118 32.118 0 0 1 9.412 22.718V304.2M175.667 272.067h64.266M288.133 207.8h48.2M288.133 304.2h48.2M312.233 207.8v96.4M256 79.267v64.266M256 432.733v-64.266"/>
        </g>
    </svg>`
};

/**
 * Represents a UI component that renders a modal for text assistant.
 */
export const TextAssistantAI: React.FC<Props> = (props) => {
    const [textAssistantData, setTextAssistantData] = useState<TextAssistantOptions>({ loading: false, copyDisabled: false, content: convert(props.textContent, convertOptions), returned: [], currentResponse: 0 });
    const [error, setError] = useState<string | undefined>(undefined);

    const submitToTextAssistant = (clearResults = false) => {
        setError(undefined);

        setTextAssistantData(prev => ({
            ...prev,
            loading: true,
            returned: clearResults ? [] : prev.returned,
            currentResponse: clearResults ? 0 : prev.currentResponse
        }));
        Shared.TextAssistant.postText(textAssistantData.skill || '', textAssistantData.content).then((response) => {
            const resp: Domain.Shared.TextAssistantResponse = response.data;
            setTextAssistantData((prev) => ({ ...prev, returned: prev.returned.concat(resp.message.content), currentResponse: prev.returned.length }));
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.InputTextTooLong)) {
                setError(Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.InputTextTooLong]);
            }
            else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PromptTextTooLong)) {
                setError(Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PromptTextTooLong]);
            }
            else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.ResultTextIsTooLong)) {
                setError(Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.ResultTextIsTooLong]);
            }
            else if (errorInfo?.details?.title === 'One or more validation errors occurred.') {
                setError(Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.InputTextTooLong]);
            }
            else {
                setError(errorInfo.message);
            }
        }).finally(() => {
            setTextAssistantData((prev) => ({ ...prev, loading: false }));
        });
    };

    const copyToClipboard = () => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported');
            return;
        }
        setTextAssistantData((prev) => ({ ...prev, copyDisabled: true }));
        setTimeout(() => setTextAssistantData((prev) => ({ ...prev, copyDisabled: false })), 3000);

        navigator.clipboard.writeText(textAssistantData.returned[textAssistantData.currentResponse] || '').catch((error) => {
            console.warn('Copy failed', error);
        });

    };

    const copyResultDisabled = textAssistantData.copyDisabled || !textAssistantData.returned.length || textAssistantData.loading;

    const renderedContent = <Styled.LayoutGrid>
        <Styled.ColumnWrapper $colStart={1} $colSpan={1} $rowStart={1} $rowSpan={3} />
        <Styled.ColumnWrapper $colStart={2} $colSpan={1} $rowStart={1} $rowSpan={3} />

        <Styled.ContentColumn $colStart={1} $colSpan={1} $rowStart={1} $rowSpan={1} className={'pt-100'}>
            <Heading2>Tekstinvoer</Heading2>
            <MultiLineTextElement
                id='input-plain-text'
                fullHeight={true}
                editorSettings={{
                    disabled: false,
                    readOnly: textAssistantData.loading,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (value: string) => setTextAssistantData((prev) => ({ ...prev, content: value, returned: [], currentResponse: 0 })),
                }}
                placeholder='Type uw tekst hier...'
                value={textAssistantData.content || ''}
            />
        </Styled.ContentColumn>
        <Styled.ContentColumn $colStart={1} $colSpan={1} $rowStart={2} $rowSpan={1}>
            <SelectElement<Domain.Shared.TextAssistantSkill>
                id='select-skill'
                label='Selecteer actie'
                displayExpr='name'
                value={props.textAssistantSkills.find(tas => tas.id === textAssistantData.skill)}
                optionItems={props.textAssistantSkills}
                clearable={false}
                searchable={false}
                placeholder='Selecteer een actie'
                editorSettings={{
                    disabled: false,
                    readOnly: textAssistantData.loading,
                    withoutFeedback: true,
                    onChange: (selectedItem) => {
                        setTextAssistantData((prev) => ({ ...prev, skill: selectedItem.id, returned: [], currentResponse: 0 }));
                    }
                }}
            />
        </Styled.ContentColumn>
        <Styled.ContentColumn $colStart={1} $colSpan={1} $rowStart={3} $rowSpan={1} className={'pb-100'}>
            <Styled.ActionWrapper>
                <Button
                    id='btn-post-skill'
                    text='Uitvoeren'
                    type='default'
                    stylingMode='contained'
                    disabled={!textAssistantData.skill || textAssistantData.loading}
                    onClick={() => submitToTextAssistant(true)}
                />
            </Styled.ActionWrapper>
        </Styled.ContentColumn>

        <Styled.ContentColumn id='div-plain-text' $colStart={2} $colSpan={1} $rowStart={1} $rowSpan={1} className={'pt-100 position-relative'}>
            <Heading3>Resultaat</Heading3>
            {textAssistantData.loading
                ? <LoadPanel
                    shading={false}
                    visible={textAssistantData.loading}
                    showPane={false}
                    position={{ of: "#div-plain-text" }}
                    text='Bezig met genereren' />
                : <>
                    <MultiLineTextElement
                        id='output-plain-text'
                        fullHeight={true}
                        editorSettings={{
                            disabled: false,
                            withoutFeedback: true,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (value: string) => setTextAssistantData((prev) => {
                                const retArray = [...prev.returned];
                                retArray[prev.currentResponse] = value;
                                return { ...prev, returned: retArray };
                            }),
                        }}
                        placeholder='Het gegenereerde resultaat zal hier verschijnen...'
                        value={textAssistantData.returned[textAssistantData.currentResponse] || ''} />
                    {!!textAssistantData.returned.length &&
                        <Styled.FloatingWrapper className={'position-absolute'}>
                            <TabularNumbers
                                steps={textAssistantData.returned.length}
                                activeStep={textAssistantData.currentResponse}
                                onPrev={() => setTextAssistantData((prev) => ({ ...prev, currentResponse: prev.currentResponse - 1 }))}
                                onNext={() => setTextAssistantData((prev) => ({ ...prev, currentResponse: prev.currentResponse + 1 }))}
                                onReSend={() => submitToTextAssistant(false)}
                            />
                        </Styled.FloatingWrapper>}
                </>
            }
        </Styled.ContentColumn>
        <Styled.ContentColumn $colStart={2} $colSpan={1} $rowStart={2} $rowSpan={1}>
            <Styled.ThumbWrap>
                <Text value='De tekstassistent maakt gebruik van AI techniek en kan af en toe fouten maken.' />
                <Text value='Het is belangrijk om het resultaat te controleren.' />
            </Styled.ThumbWrap>
        </Styled.ContentColumn>
        <Styled.ContentColumn $colStart={2} $colSpan={1} $rowStart={3} $rowSpan={1} className={'pb-100'}>
            <Styled.ActionWrapper>
                <Button
                    id='btn-copy-to-clipboard'
                    disabled={copyResultDisabled}
                    text={textAssistantData.copyDisabled ? 'Tekst Gekopieerd' : 'Tekst Kopiëren'}
                    type='default'
                    stylingMode='contained'
                    icon={getMuiIconAsSvgString(textAssistantData.copyDisabled ? TaskAltIcon : ContentCopyIcon, IconSize.medium, copyResultDisabled ? palette.grey1 : palette.white)}
                    onClick={copyToClipboard}
                />
            </Styled.ActionWrapper>
        </Styled.ContentColumn>
        {!!error && <TextAssistantErrorDialog message={error} onClose={() => setError(undefined)} />}
    </Styled.LayoutGrid>;

    if (props.standAlone) return <>{renderedContent}</>;

    return <LsModal
        id='modal-text-assistant'
        title="Tekstassistent"
        width={1300}
        height={window.innerHeight - 100}
        toolbar={{
            enabled: false,
            onLeftButtonClick: () => {
                props.onCancel();
            },
            leftButtonIcon: 'close',
        }}
    >
        {renderedContent}
    </LsModal>;

};
