import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _, { noop } from 'lodash';
import { withField, WithFieldBaseType } from '@liasincontrol/ui-devextreme';
import { RichTextProps } from './index.props';
import Styled from './index.styled';
import { getValidationErrorsNode } from '../../shared/validationHelper';

/**
 * Represents a UI component that renders a rich text editor.
 */
export const RichTextEditor: React.FC<RichTextProps> = (props) => {

    const [value, setValue] = useState<string>(props.value ? props.value : '');
    const onChangedDebounced = useMemo(() => _.debounce(props.editorSettings?.onChange || noop, 300), [props.editorSettings?.onChange]);

    useEffect(() => {
        if (!onChangedDebounced) return;

        if ((props.value || '') === value) {
            return onChangedDebounced.cancel;
        }

        onChangedDebounced(value);
        return onChangedDebounced.cancel;
    }, [value, onChangedDebounced, props.value]);

    useEffect(() => setValue(props.value ? props.value : ''), [props.value]);

    return (
        <RichTextField
            id={props.id}
            key={props.id}
            label={props.label}
            helpText={props.helpText}
            disabled={props.editorSettings?.disabled}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            mandatory={props.editorSettings?.restrictions?.required}

            value={value}
            height={props.height}
            isToolbarHidden={props.isToolbarHidden}
            onFocusIn={props.onFocusIn}
            onFocusOut={props.onFocusOut}
            onChange={setValue}
            canMaximize={props.canMaximize}
            maximized={props.maximized}
            onMaximize={props.onMaximize}
            withTextAssistant={props.withTextAssistant}
            onTextAssistant={props.onTextAssistant}
            theme={props.theme}
            withSitemapLinks={props.withSitemapLinks}
            sitemapsource={props.sitemapsource}
        />
    );
};


export type RichTextFieldProps = WithFieldBaseType<string> & {
    height?: string | number;
    isToolbarHidden?: boolean;
    withTextAssistant?: boolean;
    onFocusIn?: ((e: {}) => any);
    onFocusOut?: ((e: {}) => any);
    onTextAssistant?: () => void;
    theme?: 'light' | 'dark';
    withSitemapLinks?: boolean;
    sitemapsource?: any[];
};

export const RichTextField = withField<string, RichTextFieldProps>(
    (props) => {

        const { id, value, height, isToolbarHidden, onChange, onFocusIn, onFocusOut, ...rest } = props;

        const onValueChanged = useCallback((e: string) => {
            onChange?.(e);
        }, [onChange]);

        return <Styled.RichEditor
            id={id}
            value={value}
            onChange={onValueChanged} //onChange && useCallback((value: string) => onChange({ value, height }), [onChange, height])}
            onFocusIn={onFocusIn}
            onFocusOut={onFocusOut}
            height={height}
            isToolbarHidden={isToolbarHidden}
            {...rest}
        />
    }
);

// const RichTextField1 = withField<{
//     value: string;
//     height?: string | number;
//     isToolbarHidden?: boolean;
//     onFocusIn?: ((e: {}) => any);
//     onFocusOut?: ((e: {}) => any);
// }>(({ onChange, value: { value, height, isToolbarHidden, onFocusIn, onFocusOut }, ...rest }) => (
//     <Styled.RichEditor
//         value={value}
//         onChange={onChange && useCallback((value: string) => onChange({ value, height }), [onChange, height])}
//         onFocusIn={onFocusIn}
//         onFocusOut={onFocusOut}
//         height={height}
//         isToolbarHidden={isToolbarHidden}
//         {...rest}
//     />
// ));
