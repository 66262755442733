import { useEffect } from 'react';
import { initDevExtremeLocalization } from "@liasincontrol/ui-devextreme";
import { licenseKey } from '../../config/devextreme-license';


export const useDeveExtremeLocalization = () => {
    useEffect(() => {
        initDevExtremeLocalization(licenseKey);
    }, []);
};
